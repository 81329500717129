<template>
    <div class="container">
        <poster :poster-list="posterList" :page-path="pagePath" :module-key="moduleKey" :token="token"/>
    </div>
</template>

<script>
  import Poster from '../../../components/Poster/Index'

  export default {
    name: "FJSPoster",
    components: {
      Poster
    },
    data() {
      return {
        token: this.$route.query.token,
        posterList: [
          {
            backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210312115421488.png',
            tempBgImage: null,
            miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/miShop/20210114150134495.png',
            textColor: '#fff4cb',
            tempPoster: '',
            textPoint: [],
            wxCode: {width: 187, point: [59, 1120]}
          },
        ],
        pagePath: this.baseHost + '/',
        moduleKey: ''
      }
    },
    created() {
      this.moduleKey = this.token ? '2cb7101f-874a-43d4-92d5-f29f9617f020' : 'gzhMZmzcz202103'
        // 0ea07ee4-2e1f-493f-95c1-5b0a2ec55bb1
    }
  }
</script>

<style scoped>

</style>